import BlogContent from '../components/layout/Blog/BlogContent';


function BlogPage() {
  return (
    <section>
      <BlogContent />
    </section>
  );
}

export default BlogPage;