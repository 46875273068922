import ContactForm from '../components/layout/Contact/ContactContent';


function ContactPage() {
  return (
	  <section>
			<ContactForm />
	  </section>
  );
}

export default ContactPage;