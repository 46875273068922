import AboutContent from '../components/layout/About/AboutContent';


function AboutPage() {
  return (
    <section>
      <AboutContent />
    </section>
  );
}

export default AboutPage;